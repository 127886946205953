<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-toolbar flat dense dark color="info">
        <v-toolbar-title>
          Filtrar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon small @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="filters.customerId"
              :items="customers"
              item-text="nickname"
              item-value="id"
              label="Cliente"
              hide-details
              clearable
              :loading="loadingCustomers"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="filters.createdBy"
              :items="users"
              item-text="name"
              item-value="id"
              label="Criado por"
              hide-details
              clearable
              :loading="loadingUsers"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.completedBy"
              :items="users"
              item-text="name"
              item-value="id"
              label="Finalizado por"
              hide-details
              clearable
              :loading="loadingUsers"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-date
              v-model="filters.createdAtStart"
              label="Criado de"
              hide-details
              clearable
            />
          </v-col>
          <v-col>
            <input-date
              v-model="filters.createdAtEnd"
              label="Criado até"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-date
              v-model="filters.deliveryDeadlineDateStart"
              label="Vencimento de"
              hide-details
              clearable
            />
          </v-col>
          <v-col>
            <input-date
              v-model="filters.deliveryDeadlineDateEnd"
              label="Vencimento até"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-date
              v-model="filters.completedAtStart"
              label="Finalizado de"
              hide-details
              clearable
            />
          </v-col>
          <v-col>
            <input-date
              v-model="filters.completedAtEnd"
              label="Finalizado até"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-date
              v-model="filters.alertDateStart"
              label="Data de alerta de"
              hide-details
              clearable
            />
          </v-col>
          <v-col>
            <input-date
              v-model="filters.alertDateEnd"
              label="Data de alerta até"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-select
              v-model="filters.completed"
              :items="[
                {
                  value: 0,
                  description: 'Todos',
                },
                {
                  value: 1,
                  description: 'Sim',
                },
                {
                  value: 2,
                  description: 'Não',
                },
              ]"
              item-text="description"
              item-value="value"
              label="Finalizado"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="info" outlined @click="filter">
          <v-icon left v-text="'mdi-filter'" />
          Pesquisar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import customersApi from '@/api/customers'
import usersApi from '@/api/users'

export default {
  components: {
    InputDate: () => import('@/components/input/InputDate'),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      loadingCustomers: false,
      loadingUsers: false,
      customers: [],
      users: [],
      filters: {
        completed: 0,
      },
      formatedStartDate: null,
      formatedEndDate: null,
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    this.loadCustomers()
    this.loadUsers()
  },

  methods: {
    async loadCustomers() {
      try {
        this.loadingCustomers = true
        const response = await customersApi.list()
        this.customers = response.data.customers
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingCustomers = false
      }
    },

    async loadUsers() {
      try {
        this.loadingUsers = true
        const response = await usersApi.list()
        this.users = response.data.users
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingUsers = false
      }
    },

    filter() {
      this.show = false
      this.$emit('filter', this.filters)
    },
  },
}
</script>
